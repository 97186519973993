import store from '../store';

export default async (to, from, next) => {
  // Redirecting unauthenticated user to Signin
  if (store.getters.userId && !store.getters.user) {
    next({
      path: '/userVerification',
    });
  } else if (!store.getters.user && !localStorage.getItem('chempooToken')) {
    if (to.name !== 'signin' && to.name !== 'signup') {
      next({
        path: '/signin',
      });
    }
  } else {
    next();
  }
};
