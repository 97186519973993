import { mdiPencil } from '@mdi/js';
import Cookies from 'js-cookie';

const app = {
  // Common
  statusOverlayText: '',
  isStatusOverlay: false,
  appName: process.env.VUE_APP_NAME,
  appEnv: {},
  appDark: false,
  initializeAppComplete: false,
  loading: false,
  error: null,
  tempData: {},
  validationRules: {
    required: [
      (value) => !!value || 'Required.',
      (value) => value !== 'Select' || 'Required.',
    ],
    otp: [
      (value) => !!value || 'Required.',
      (value) =>
        (value && value.toString().length === 6) || 'OTP should have 6 digits',
    ],
    radioRequired: [(value) => value >= 0 || 'Required.'],
    requiredArray: [
      (value) => !!(value && value.length) || 'At least one required',
    ],
    password: [
      (value) => !!value || 'Please enter a password',
      (value) =>
        (value &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/.test(
            value
          )) ||
        'Minimum 6 characters, One capital latter, Special charater, Number',
    ],
    name: [
      (value) => !!value || 'Required.',
      (value) => {
        const pattern = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        return pattern.test(value) || 'Invalid Name.';
      },
    ],
    postalCode: [
      (value) => !!value || 'Required.',
      (value) => {
        const pattern = /^[1-9][0-9]{5}$/;
        return pattern.test(value) || 'Invalid Postal Code.';
      },
    ],
    cvv: [
      (value) => !!value || 'Required.',
      (value) => {
        const pattern = /^\d{3,4}$/;
        return pattern.test(value) || 'Invalid cvv';
      },
    ],
    postalCodeOptional: [
      (value) => {
        const pattern = /^[1-9][0-9]{5}$/;
        return pattern.test(value) || 'Invalid Postal Code.';
      },
    ],
    mobileNumber: [
      (value) => !!value || 'Required.',
      (value) => {
        const pattern = /^[0]?[789]\d{9}$/;
        return pattern.test(value) || 'Invalid phone number.';
      },
    ],
    areaCode: [
      (value) => !!value || 'Required.',
      (value) => value.length == 3 || '3 digits for Area Code.',
    ],
    localNumber: [
      (value) => !!value || 'Required.',
      (value) => value.length == 7 || '7 digits for local number.',
    ],
    cardNumber: [
      (value) => !!value || 'Required.',
      (value) => {
        const pattern1 = /^(?:5[1-5][0-9]{14})$/;
        const pattern2 = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
        const pattern3 = /^(?:3[47][0-9]{13})$/;
        return (
          pattern1.test(value) ||
          pattern2.test(value) ||
          pattern3.test(value) ||
          'Invalid Card number.'
        );
      },
    ],
    counter: (value) => value.length <= 20 || 'Max 20 characters',
    min: (v) => v.length >= 8 || 'Min 8 characters',
    emailMatch: () => "The email and password you entered don't match",
    // comparePasswords: () =>
    //   this.password !== this.confirmpassword ? "Passwords do not match" : "",
    email: [
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail.';
      },
    ],
    deleteProjects: [
      (value) => !!(value && value.length) || 'At least one required',
      (value) =>
        !!(value && value.length <= 5) ||
        'Maximum of number of projects allowed is 5',
    ],
  },
  progressing: false,
  sideNav: false,
  showSideNavigationDrawer: true,
  cloneFlag: false,
  snackBar: false,
  targetRequired: false,
  targetLink: '',
  targetText: '',
  snackBarText: '',
  homeScreenData: {
    itemCategoryData: [],
    userItemCategoryData: [],
  },
  homePageData: {},
  homePageDataReceived: false,
  homePageCarousel: {},
  homePageCarouselImages: [],
  homPageCarouselReceived: false,
  icons: {
    mdiPencil,
  },
  appMenus: [],
  menuContext: '',
  activeMenu: {},
  menuDialog: false,
  isMenuFormValid: false,
  appMenusReceived: false,
  userItemCategories: [],
};
const user = {
  user: null,
  userId: '',
  userEmail: '',
  resetPasswordActive: false,
  userCard: false,
  ignoreApolloStoreReset: false,
  token: Cookies.get('chempooToken') || '',
  axiosConfig: {},
  firstName: '',
  lastName: '',
  userVerificationContext: '',
  subscriberFirstName: '',
  subscriberLastName: '',
  subscriberEmail: '',
  ordersByUserReceived: false,
  subscriptionDialog: false,
  addCellNumberDialog: false,
  verifyOtpDialog: false,
  cellNumberOtp: '',
  cellNumberCountryCode: '',
  cellNumberNumber: '',
  countryCodes: ['+1', '+1', '+52', '+61', '+91', '+81'],
  countryPhoneCodes: {},
  orderStatusCodes: {},
  orderInProgressPhoneVerificationFlag: false,
  userAuthenticationByPhoneFlag: false,
  userAccountPhoneVerificationFlag: false,
  phoneVerificationFlag: false,
  ordersForAdmin: [],
  ordersByAdminReceived: false,
};
const ckEditor = {
  ckEditorKey: 1,
  editorData: '<p></p>',
  ckEditorConfig: {
    fontSize: {
      options: [
        6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 36, 48, 60, 72, 84, 96,
      ],
    },
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        'undo',
        'redo',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        'removeFormat',
        '|',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        'fontSize',
        '|',
        'indent',
        'outdent',
        'alignment',
        'numberedList',
        'bulletedList',
        'blockQuote',
        '|',
        'imageInsert',
        'imageUpload',
        'link',
        '|',
        'insertTable',
        // 'mediaEmbed',
        '|',
        'horizontalLine',
        'pageBreak',
        'superscript',
        'subscript',
        'specialCharacters',
        'findAndReplace',
        '|',
        'customButton',
      ],
      ui: {
        viewportOffset: {
          top: 30,
        },
      },
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    exportPdf: {
      stylesheets: ['EDITOR_STYLES'],
      fileName: 'document.pdf',
      converterOptions: {
        format: 'A4',
        margin_top: '20mm',
        margin_bottom: '20mm',
        margin_right: '12mm',
        margin_left: '12mm',
        page_orientation: 'portrait',
      },
    },
    contentStyle: '../../main.css',
    licenseKey: 'iBWm5ZN/ec3bCOfJHUMy68Kz832EZNUzHkRyGqpv5r6o7xdZANiqLzE1Yw==',
  },
  ckEditorReadOnly: false,
  ckEditorConfigCustom: {
    fontSize: {
      options: [
        6, 7, 8, 9, 10, 11, 12, 14, 16, 18, 20, 24, 36, 48, 60, 72, 84, 96,
      ],
    },
    toolbar: {
      shouldNotGroupWhenFull: true,
      items: [
        'heading',
        'undo',
        'redo',
        '|',
        'bold',
        'underline',
        'strikethrough',
        'italic',
        'removeFormat',
        '|',
        'fontFamily',
        'fontColor',
        'fontBackgroundColor',
        'fontSize',
        '|',
        'indent',
        'outdent',
        'alignment',
        'numberedList',
        'bulletedList',
        'blockQuote',
        '|',
        'imageInsert',
        'imageUpload',
        'link',
        '|',
        'insertTable',
        // 'mediaEmbed',
        '|',
        'horizontalLine',
        'pageBreak',
        'superscript',
        'subscript',
        'specialCharacters',
        'findAndReplace',
        '|',
        // 'customButton',
      ],
      ui: {
        viewportOffset: {
          top: 30,
        },
      },
    },
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side',
        'linkImage',
      ],
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells',
        'tableCellProperties',
        'tableProperties',
      ],
    },
    licenseKey: 'iBWm5ZN/ec3bCOfJHUMy68Kz832EZNUzHkRyGqpv5r6o7xdZANiqLzE1Yw==',
  },
  isCkEditorContainerDialog: false,
};
const bom = {
  itemSearchText: '',
  itemAdditionalInfo: 'Additional info for item',
  isItemAdditionalInfoDialog: false,
  searchInProgress: false,
  itemModels: [],
  itemCategories: [],
  itemModelDataReceived: false,
  items: [],
  dealItems: [],
  bulkItems: [],
  bulkItemsReceived: false,
  bulkItemsTitle: '',
  itemDataReceived: false,
  itemModelMode: 'create',
  activeItemModel: {
    name: 'Item Model Name appears here...',
    description: 'Item Model description appears here...',
    category: 'Item Category',
    isSizeApplicable: false,
    isColorApplicable: false,
    disabled: false,
    catId: '',
    subCategory: '',
    group: '',
    published: false,
    tags: [],
    images: [],
    specs: [],
  },
  activeItem: {
    name: 'Item Name appears here...',
    description: 'Item description appears here...',
    category: 'Item Category',
    isSizeApplicable: false,
    isColorApplicable: false,
    disabled: false,
    catId: '',
    subCategory: '',
    group: '',
    published: false,
    tags: [],
    images: [],
    defaultImage: '',
    specs: [],
    price: {
      value: null,
      currency: process.env.VUE_APP_CURRENCY,
    },
    stock: 0,
    tax: null,
    discount: 20,
    sku: '',
    model: '',
    routeParam: '',
    featuresDetails: [],
    customerQuestions: [],
    rating: {
      rateCount: null,
      rateCount1: null,
      rateCount2: null,
      rateCount3: null,
      rateCount4: null,
      rateCount5: null,
      rateAvg: null,
      ratings: [],
    },
    reviews: [],
  },
  cleanItem: {
    name: 'Item Name appears here...',
    description: 'Item description appears here...',
    category: 'Item Category',
    tags: [],
    images: [],
    defaultImage: '',
    specs: [],
    price: {
      value: null,
      currency: process.env.VUE_APP_CURRENCY,
    },
    stock: 0,
    tax: null,
    discount: 10,
    sku: '',
    model: '',
    routeParam: '',
    featuresDetails: [],
    customerQuestions: [],
    rating: {
      rateCount: null,
      rateCount1: null,
      rateCount2: null,
      rateCount3: null,
      rateCount4: null,
      rateCount5: null,
      rateAvg: null,
      ratings: [],
    },
    reviews: [],
  },
  itemMode: 'create',
  itemReviewDialog: false,
  reviewsByUserDataReceived: false,
  isItemInitiateDialog: false,
  itemModelSelect: {},
  defaultCart: {
    items: [],
    shippingAddress: null,
    billingAddress: null,
    promoCode: '',
    subTotal: null,
    tax: null,
    total: null,
    currency: 'INR',
    user: null,
  },
  cart: {
    items: [],
    shippingAddress: null,
    billingAddress: null,
    promoCode: '',
    subTotal: null,
    tax: null,
    total: null,
    currency: 'INR',
    user: null,
  },
  cartSubTotal: null,
  cartDiscount: null,
  cartTax: null,
  cartShippingFee: null,
  cartConvenienceFee: null,
  cartTotal: null,
  checkoutModel: 1,
  shippingAddresses: [],
  billingAddresses: [],
  shippingInfoComplete: true,
  billingInfoComplete: false,
  deliveryInfoComplete: true,
  reviewOrderComplete: true,
  addressContext: 'add',
  addressDialog: false,
  activeAddresssCategory: null,
  addressContext: 'add',
  addressDialog: false,
  isAddressFormValid: false,
  activeAddress: {
    category: 'residential',
    addresseeFirst: '',
    addresseeLast: '',
    line1: '',
    line2: '',
    landmark: '',
    stateProvince: '',
    cityTown: '',
    country: 'India',
    postalCode: '',
    phoneNumber: {
      category: 'Mobile',
      countryCode: '91',
      areaCode: '',
      localNumber: '',
      mobileNumber: '',
    },
  },
  statesProvinces: [],

  monthArray: [
    '01',
    '02',
    '03',
    '04',
    '05',
    '06',
    '07',
    '08',
    '09',
    '10',
    '11',
    '12',
  ],
  cardYearArray: Array.from(new Array(21), (x, i) =>
    (i + new Date().getFullYear()).toString()
  ),
  orderDialog: false,
  activeOrder: {
    orderNumber: null,
    deliveryEstimate: '',
    orderDate: '',
    orderStatus: '',
    canceled: false,
    orderComplete: false,
    items: [],
    subTotal: null,
    billingAddress: '',
    billingAddress: '',
    promotion: null,
    promoCode: '',
    tax: null,
    orderValue: null,
    orderCurrency: '',
    user: '',
  },
  orders: [],
  searchItemsDialog: false,
  itemSearchResultsData: {},
  optionSpecs: [],
  minMaxSpecs: [],
  itemSearchCategories: [],
  itemSearchColors: [],
  itemSearchSizes: [],
  showSearchResultsButton: false,
  itemSearchPage: null,
  itemSearchNumberOfPages: null,
  itemMinPrice: parseFloat(process.env.VUE_APP_ITEM_MIN_PRICE),
  itemMaxPrice: parseFloat(process.env.VUE_APP_ITEM_MAX_PRICE),
  itemPriceSortOrder: 'asc',
  sideAdavncedItemFilter: false,
  appCategories: [],
  categoryContext: '',
  activeCategory: {},
  categoryDialog: false,
  isCategoryFormValid: false,
  appCategoriesReceived: false,
};
const page = {
  pages: [],
  pageMode: 'create',
  activePageRow: {},
  activePageRowIndex: null,
  activePage: {
    name: 'Name of the page',
    routeParam: '',
    description: 'Page Description',
    pageRows: [],
    isItemPage: false,
    itemDetails: {
      category: '',
      subCategory: '',
      group: '',
    },
  },
  activePageDataReceived: false,
  navigablePageComponents: ['Item', 'Resource'],
  pageCloningActive: false,
  itemPageNumber: null,
  itemPageNumberOfPages: null,
  itemPageData: {},
  itemPageDataReceived: false,
};
const unitMatrix = {
  unitMatrices: [],
  pressureUnits: [
    'kg/cm² g',
    'bar g',
    'psi g',
    'mm WC',
    'kg/cm² a',
    'bar a',
    'psi a',
    'atm',
  ],
  tempUnits: ['deg F', 'deg C', 'K', 'R'],
  timeUnits: [],
  volumetricFlowrateUnits: [],
  densityUnits: [],
  lengthUnits: [],
  volumeUnits: [],
  velocityUnits: [],
  pressureDiffUnits: [],
  stressUnits: [],
  viscosityUnits: [],
  timeUnitsMatrix: [],
  volFlowRateUnitsMatrix: [],
  densityUnitsMatrix: [],
  lengthUnitsMatrix: [],
  lengthUnitsMatrix2: [],
  volumeUnitsMatrix: [],
  velocityUnitsMatrix: [],
  densityUnitSelect: 'kg/m³',
  pipeSizeMatrix: [
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 5s',
      thickness: 0.889,
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 5s',
      thickness: 1.245,
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 5s',
      thickness: 1.245,
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 5s',
      thickness: 1.651,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 5s',
      thickness: 1.651,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 5s',
      thickness: 1.651,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 5s',
      thickness: 1.651,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 5s',
      thickness: 1.651,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 5s',
      thickness: 1.651,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 5s',
      thickness: 2.108,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 5s',
      thickness: 2.108,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 5s',
      thickness: 2.108,
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 10s/20',
      thickness: 1.245,
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 10s/20',
      thickness: 1.651,
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 10s/20',
      thickness: 1.651,
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 10s/20',
      thickness: 2.108,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 10s/20',
      thickness: 2.108,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 10s/20',
      thickness: 2.769,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 10s/20',
      thickness: 2.769,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 10s/20',
      thickness: 2.769,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 10s/20',
      thickness: 2.769,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 10s/20',
      thickness: 3.048,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 10s/20',
      thickness: 3.048,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 10s/20',
      thickness: 3.048,
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 30',
      thickness: 1.448,
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 30',
      thickness: 1.854,
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 30',
      thickness: 1.854,
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 30',
      thickness: 2.413,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 30',
      thickness: 2.413,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 30',
      thickness: 2.896,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 30',
      thickness: 2.972,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 30',
      thickness: 3.175,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 30',
      thickness: 3.175,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 30',
      thickness: 4.775,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 30',
      thickness: 4.775,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 30',
      thickness: 4.775,
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 40s/40/STD',
      thickness: 1.727,
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 40s/40/STD',
      thickness: 2.235,
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 40s/40/STD',
      thickness: 2.311,
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 40s/40/STD',
      thickness: 2.769,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 40s/40/STD',
      thickness: 2.87,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 40s/40/STD',
      thickness: 3.378,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 40s/40/STD',
      thickness: 3.556,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 40s/40/STD',
      thickness: 3.683,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 40s/40/STD',
      thickness: 3.912,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 40s/40/STD',
      thickness: 5.156,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 40s/40/STD',
      thickness: 5.486,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 40s/40/STD',
      thickness: 5.74,
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 80s/80/XS',
      thickness: 2.413,
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 80s/80/XS',
      thickness: 3.023,
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 80s/80/XS',
      thickness: 3.2,
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 80s/80/XS',
      thickness: 3.734,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 80s/80/XS',
      thickness: 3.912,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 80s/80/XS',
      thickness: 4.547,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 80s/80/XS',
      thickness: 4.851,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 80s/80/XS',
      thickness: 5.08,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 80s/80/XS',
      thickness: 5.537,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 80s/80/XS',
      thickness: 7.01,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 80s/80/XS',
      thickness: 7.62,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 80s/80/XS',
      thickness: 8.077,
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 120',
      thickness: 6.35,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 120',
      thickness: 7.62,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 120',
      thickness: 8.89,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 120',
      thickness: 'NA',
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'SCH 160',
      thickness: 'NA',
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'SCH 160',
      thickness: 'NA',
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'SCH 160',
      thickness: 'NA',
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'SCH 160',
      thickness: 4.775,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'SCH 160',
      thickness: 5.563,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'SCH 160',
      thickness: 6.35,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'SCH 160',
      thickness: 6.35,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'SCH 160',
      thickness: 7.137,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'SCH 160',
      thickness: 8.738,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'SCH 160',
      thickness: 9.525,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'SCH 160',
      thickness: 11.125,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'SCH 160',
      thickness: 'NA',
    },
    {
      size: '⅛"',
      sizeDN: 6,
      outerDia: 10.26,
      schedule: 'XXS',
      thickness: 'NA',
    },
    {
      size: '¼"',
      sizeDN: 8,
      outerDia: 13.72,
      schedule: 'XXS',
      thickness: 'NA',
    },
    {
      size: '⅜"',
      sizeDN: 10,
      outerDia: 17.15,
      schedule: 'XXS',
      thickness: 'NA',
    },
    {
      size: '½"',
      sizeDN: 15,
      outerDia: 21.34,
      schedule: 'XXS',
      thickness: 7.468,
    },
    {
      size: '¾"',
      sizeDN: 20,
      outerDia: 26.67,
      schedule: 'XXS',
      thickness: 7.823,
    },
    {
      size: '1"',
      sizeDN: 25,
      outerDia: 33.4,
      schedule: 'XXS',
      thickness: 9.093,
    },
    {
      size: '1¼"',
      sizeDN: 32,
      outerDia: 42.16,
      schedule: 'XXS',
      thickness: 9.703,
    },
    {
      size: '1½"',
      sizeDN: 40,
      outerDia: 48.26,
      schedule: 'XXS',
      thickness: 10.16,
    },
    {
      size: '2"',
      sizeDN: 50,
      outerDia: 60.33,
      schedule: 'XXS',
      thickness: 11.074,
    },
    {
      size: '2½"',
      sizeDN: 65,
      outerDia: 73.03,
      schedule: 'XXS',
      thickness: 14.021,
    },
    {
      size: '3"',
      sizeDN: 80,
      outerDia: 88.9,
      schedule: 'XXS',
      thickness: 15.24,
    },
    {
      size: '3½"',
      sizeDN: 90,
      outerDia: 101.6,
      schedule: 'XXS',
      thickness: 16.154,
    },
  ],
  pressureDiffUnitsMatrix: [],
  stressUnitsMatrix: [],
  viscosityUnitsMatrix: [],
  viscosityUnitSelect: 'cP',
  tubeSizeMatrix: [
    {
      size: '⅛"',
      outerDia: 3.175,
      thickness: 0.889,
    },
    {
      size: '¼"',
      outerDia: 6.35,
      thickness: 0.89,
    },
    {
      size: '⅜"',
      outerDia: 9.525,
      thickness: 0.892499999999999,
    },
    {
      size: '½"',
      outerDia: 12.7,
      thickness: 1.245,
    },
    {
      size: '¾"',
      outerDia: 19.05,
      thickness: 1.245,
    },
  ],
  spHeatRatioMatrix: [
    {
      name: 'Acetone',
      value: 1.11,
    },
    {
      name: 'Acetylene',
      value: 1.3,
    },
    {
      name: 'Air, Standard',
      value: 1.4,
    },
    {
      name: 'Ammonia',
      value: 1.32,
    },
    {
      name: 'Argon',
      value: 1.66,
    },
    {
      name: 'Benzene',
      value: 1.12,
    },
    {
      name: 'Blast furnace gas',
      value: 1.41,
    },
    {
      name: 'Bromine',
      value: 1.28,
    },
    {
      name: 'Butane',
      value: 1.094,
    },
    {
      name: 'Butatiene',
      value: 1.12,
    },
    {
      name: 'Carbon Dioxide',
      value: 1.28,
    },
    {
      name: 'Carbon Disulphide',
      value: 1.21,
    },
    {
      name: 'Carbon Monoxide',
      value: 1.4,
    },
    {
      name: 'Chlorine',
      value: 1.33,
    },
    {
      name: 'Chloroform',
      value: 1.15,
    },
    {
      name: 'Ethane',
      value: 1.18,
    },
    {
      name: 'Ether',
      value: 1.03,
    },
    {
      name: 'Ethyl alcohol',
      value: 1.13,
    },
    {
      name: 'Ethyl chloride',
      value: 1.19,
    },
    {
      name: 'Ethylene',
      value: 1.24,
    },
    {
      name: 'Freon 22',
      value: 1.18,
    },
    {
      name: 'Helium',
      value: 1.66,
    },
    {
      name: 'Hexane',
      value: 1.06,
    },
    {
      name: 'Hydrochloric acid',
      value: 1.41,
    },
    {
      name: 'Hydrogen',
      value: 1.41,
    },
    {
      name: 'Hydrogen chloride',
      value: 1.41,
    },
    {
      name: 'Hydrogen Sulfide',
      value: 1.32,
    },
    {
      name: 'Hydrogen sulphide',
      value: 1.32,
    },
    {
      name: 'Iso-butane',
      value: 1.19,
    },
    {
      name: 'Iso-pentane',
      value: 1.08,
    },
    {
      name: 'Methane',
      value: 1.32,
    },
    {
      name: 'Methyl alcohol',
      value: 1.2,
    },
    {
      name: 'Methyl butane',
      value: 1.08,
    },
    {
      name: 'Methyl chloride',
      value: 1.2,
    },
    {
      name: 'Natural Gas',
      value: 1.27,
    },
    {
      name: 'Natural Gas (Methane)',
      value: 1.32,
    },
    {
      name: 'N-butane',
      value: 1.18,
    },
    {
      name: 'Neon',
      value: 1.667,
    },
    {
      name: 'N-heptane',
      value: 1.05,
    },
    {
      name: 'Nitric oxide',
      value: 1.4,
    },
    {
      name: 'Nitrogen',
      value: 1.4,
    },
    {
      name: 'Nitrogen tetroxide',
      value: 1.02,
    },
    {
      name: 'Nitrous oxide',
      value: 1.31,
    },
    {
      name: 'N-octane',
      value: 1.05,
    },
    {
      name: 'N-pentane',
      value: 1.08,
    },
    {
      name: 'Oxygen',
      value: 1.4,
    },
    {
      name: 'Pentane',
      value: 1.07,
    },
    {
      name: 'Propane',
      value: 1.13,
    },
    {
      name: 'Propene (propylene)',
      value: 1.15,
    },
    {
      name: 'R-11',
      value: 1.14,
    },
    {
      name: 'R-114',
      value: 1.09,
    },
    {
      name: 'R-12',
      value: 1.14,
    },
    {
      name: 'R-123',
      value: 1.1,
    },
    {
      name: 'R-134a',
      value: 1.2,
    },
    {
      name: 'R-22',
      value: 1.18,
    },
    {
      name: 'Steam (water)',
      value: 1.33,
    },
    {
      name: 'Steam 1 psia. 120 – 600 oF',
      value: 1.32,
    },
    {
      name: 'Steam 14.7 psia. 220 – 600 oF',
      value: 1.31,
    },
    {
      name: 'Steam 150 psia. 360 – 600 oF',
      value: 1.28,
    },
    {
      name: 'Sulfur dioxide (Sulphur dioxide)',
      value: 1.29,
    },
    {
      name: 'Sulphur dioxide',
      value: 1.26,
    },
    {
      name: 'Toulene',
      value: 1.09,
    },
    {
      name: 'Water Vapor',
      value: 1.32,
    },
  ],
  roughnessMatrix: [
    {
      name: 'Drawn Tubing, Glass, Plastic',
      value: '0.0015 - 0.01',
    },
    {
      name: 'Drawn Brass, Copper, Stainless Steel (New)',
      value: '>0.0015 - 0.01',
    },
    {
      name: 'Flexible Rubber Tubing - Smooth',
      value: '0.006 - 0.07',
    },
    {
      name: 'Flexible Rubber Tubing - Wire Reinforced',
      value: '0.3 - 4',
    },
    {
      name: 'Stainless Steel',
      value: '0.03',
    },
    {
      name: 'Wrought Iron (New)',
      value: '0.045',
    },
    {
      name: 'Carbon Steel (New)',
      value: '0.02 - 0.05',
    },
    {
      name: 'Carbon Steel (Slightly Corroded)',
      value: '0.05 - 0.15',
    },
    {
      name: 'Carbon Steel (Moderately Corroded)',
      value: '0.15 - 1',
    },
    {
      name: 'Carbon Steel (Badly Corroded)',
      value: '1 - 3',
    },
    {
      name: 'Carbon Steel (Cement-lined)',
      value: '1.5',
    },
    {
      name: 'Asphalted Cast Iron',
      value: '0.1 - 1',
    },
    {
      name: 'Cast Iron (new)',
      value: '0.25',
    },
    {
      name: 'Cast Iron (old, sandblasted)',
      value: '1',
    },
    {
      name: 'Sheet Metal Ducts (with smooth joints)',
      value: '0.02 - 0.1',
    },
    {
      name: 'Galvanized Iron',
      value: '0.025 - 0.15',
    },
    {
      name: 'Wood Stave',
      value: '0.18 - 0.91',
    },
    {
      name: 'Wood Stave, used',
      value: '0.25 - 1',
    },
    {
      name: 'Smooth Cement',
      value: '0.5',
    },
    {
      name: 'Concrete – Very Smooth',
      value: '0.025 - 0.2',
    },
    {
      name: 'Concrete – Fine (Floated, Brushed)',
      value: '0.2 - 0.8',
    },
    {
      name: 'Concrete – Rough, Form Marks',
      value: '0.8 - 3',
    },
    {
      name: 'Riveted Steel',
      value: '0.91 - 9.1',
    },
    {
      name: 'Water Mains with Tuberculations',
      value: '1.2',
    },
    {
      name: 'Brickwork, Mature Foul Sewers',
      value: '3',
    },
  ],
};
const reLoginData = {
  // Re-Login Dialog
  isReLoggingIn: false,
  reLogInDialog: false,
  reLogInDialogHeading: 'You are about to be Logged Out',
  reLogInText:
    "Your session is about to expire in 30 minutes. Please click on 'Re-Login' to stay logged in. Otherwise, you will be logged out at the end of 30 minutes from now.Ensure that you save your work in advance.",
  isReLogInFormValid: true,
  displayReLogInPassword: false,
  reLogInPassword: '',
};
const appResources = {
  resourceCategories: ['General', 'Constructor'],
  editedTableItem: {},
  defaultTableItem: {},
  resourceDataReceived: false,
  activeResource: {},
  resourceMode: 'create',
  alphaResourceViewTitle: '',
  alphaResourceViewIntro: '',
  alphaResourceViewCategory: '',
  alphaResourceViewContent: [],
  alphaResourceViewTags: [],
  alphaResourceViewReferences: [],
  appTags: [],
  activeReferenceIndex: null,
  alphaResourceReferenceDialog: false,
  resourceType: 'alpha',
  alphaResourceTags: [],
  appResourceTags: [],
  alphaResourceReferences: [],
  generalResources: [],
  processAnalyzerResources: [],
  processCalculationResources: [],
  constructorResources: [],
  alphaResources: [],
  constructorAlphaResources: [],
  alphaResourceTitle: 'Resource Title',
  alphaResourceContentIntro: '<p>Introduction for Resource</p>',
  alphaResourceCategory: 'General',
  alphaResourceContent: [],
  activeResourceContent: null,
  activeContentIndex: null,
  alphaResourceTitleDialog: false,
  isAlphaResourceIntroDialog: false,
  isAlphaResourceContentDialog: false,
};
// COnsolidating state variables
const getDefaultState = () => {
  return {
    ...app,
    ...user,
    ...ckEditor,
    ...bom,
    ...page,
    ...unitMatrix,
    ...reLoginData,
    ...appResources,
  };
};

export default getDefaultState;
