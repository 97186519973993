// axios.js
import axios from 'axios';
// import store from "../store.js";
// let token;

// if (!store.getters.token) {
//   token = localStorage.getItem("chempooToken") || "";
// } else {
//   token = this.$store.getters.token;
// }
const vueAppBaseUrl =
  process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_BASE_URL
    : process.env.VUE_APP_BASE_URL_DEV;
axios.defaults.baseURL = vueAppBaseUrl;
// axios.defaults.headers.common = { Authorization: `bearer ${token}` };
export default axios;
