<template>
  <!-- progress linear -->
  <!-- :dark="!appDark"
    :light="appDark" -->
  <div>
    <v-progress-linear
      indeterminate
      color="yellow darken-2"
    ></v-progress-linear>
    <br>
    <v-progress-linear
      indeterminate
      color="green"
    ></v-progress-linear>
    <br>
    <v-progress-linear
      indeterminate
      color="teal"
    ></v-progress-linear>
    <br>
    <v-progress-linear
      indeterminate
      color="cyan"
    ></v-progress-linear>
  </div>
  </div>
</template>

<script>
export default {};
</script>