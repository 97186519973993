var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"ma-0 pa-0",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"wrap":"","justify":"center"}},[_c('v-col',{staticClass:"text-center mx-auto my-0 py-0",attrs:{"align":"center","cols":"12","md":"12","sm":"12"}},[_c('v-dialog',{attrs:{"content-class":"display__resource-image","max-width":"1100"},model:{value:(_vm.isExpandedImageDialog),callback:function ($$v) {_vm.isExpandedImageDialog=$$v},expression:"isExpandedImageDialog"}},[_c('v-card',{staticClass:"ma-0 pa-0"},[_c('v-img',{staticClass:"mx-auto",staticStyle:{"max-height":"90%"},attrs:{"src":_vm.expandedImageUrl,"alt":"Resource Image","id":"thumbnail"}},[_c('v-card-title',{staticClass:"align-start jsutify-end fill-height ma-0 pa-0",attrs:{"primary-title":""}},[_c('v-btn',{staticStyle:{"z-index":"9999"},attrs:{"medium":"","icon":""},on:{"click":function($event){_vm.isExpandedImageDialog = false}}},[_c('v-icon',{class:_vm.appDark ? 'black' : 'white'},[_vm._v("close")])],1)],1)],1)],1)],1)],1)],1),_c('confirm-dialog',{attrs:{"isDialog":_vm.isDialog,"dialogHeading":_vm.dialogHeading,"dialogText":_vm.dialogText,"dialogText2":_vm.dialogText2,"dialogBtn1":_vm.dialogBtn1,"dialogBtn2":_vm.dialogBtn2},on:{"confirmedNo":function($event){return _vm.clearDialog('no')},"confirmedYes":function($event){return _vm.clearDialog('yes')}}}),(_vm.isTemplate ? _vm.pageResourceDataReceived : _vm.resourceDataReceived)?_c('div',[_c('v-row',{staticClass:"my-0 mx-1 pa-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('div',{staticClass:"ma-0 pa-0"},[_c('v-card',{staticClass:"ma-0 pa-0",class:_vm.isNavigationEnabled
                ? 'navigation__resource--page'
                : 'navigation__disabled',attrs:{"flat":"","ripple":false},on:{"click":_vm.showRelatedContent}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"wrap":"","justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-left ma-0 pa-0",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-toolbar',{staticClass:"toolbar--title",style:(_vm.appLightBackground),attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.isTemplate ? _vm.title ? _vm.title : _vm.constructorResource.title : _vm.alphaResourceViewTitle))]),(!_vm.isTemplate)?_c('v-spacer'):_vm._e(),(
                      !_vm.isTemplate &&
                      _vm.user &&
                      (_vm.user.admin || _vm.user.resourceCreator)
                    )?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 custom-transform-class text-none",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.handleDialog(null, 'disableResource')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("disabled_visible")])],1)]}}],null,false,3534611408)},[_c('span',[_vm._v("Disable this resource")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 custom-transform-class text-none",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.handleEditAlphaResource(false)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,1978806867)},[_c('span',[_vm._v("Edit this resource")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 custom-transform-class text-none",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.handleEditAlphaResource(true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("control_point_duplicate")])],1)]}}],null,false,1756918904)},[_c('span',[_vm._v("Clone as new resource")])])],1):_vm._e()],1)],1)],1),_c('div',[_c('v-card',{staticClass:"ma-0 pa-0 pointerMouse",style:(_vm.appLightBackground),attrs:{"elevation":"0"}},[_c('v-card-text',{staticClass:"pa-0 ma-0",attrs:{"elevation":"0"}},[_c('v-card',{staticClass:"ma-0 pa-0",attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-card-text',{staticClass:"ma-0 pa-0",class:_vm.isNavigationEnabled
                              ? 'navigation__resource--page'
                              : 'navigation__disabled',attrs:{"elevation":"0"}},[_c('span',{staticClass:"appFont1",domProps:{"innerHTML":_vm._s(
                              _vm.isTemplate
                                ? _vm.constructorResource.contentIntro
                                : _vm.alphaResourceViewIntro
                            )}})]),(
                            _vm.isTemplate
                              ? _vm.constructorResource.content &&
                                _vm.constructorResource.content.length
                              : _vm.alphaResourceViewContent &&
                                _vm.alphaResourceViewContent.length
                          )?_c('div',_vm._l((_vm.isTemplate
                              ? _vm.constructorResource.content
                              : _vm.alphaResourceViewContent),function(content,contentIndex){return _c('v-card-text',{key:contentIndex,staticClass:"ma-0 pa-0",class:_vm.isNavigationEnabled
                                ? 'navigation__resource--page'
                                : 'navigation__disabled',attrs:{"elevation":"0"}},[_c('v-row',{staticClass:"px-0 py-3 mx-0 mt-2",attrs:{"justify":"center"}},[(
                                  content.imageRequired &&
                                  content.imageLink &&
                                  content.imageOnLeft
                                )?_c('v-col',{staticClass:"text-left ma-0 pa-0",attrs:{"cols":"12","md":!content.contentDetail ? 12 : 4,"sm":!content.contentDetail ? 12 : 4,"xs":"12"}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ma-0 pa-0",class:!content.contentDetail
                                        ? 'text-center'
                                        : 'text-left'},[_c('v-img',{staticClass:"ma-0 pa-0",attrs:{"src":content.imageUrl,"alt":"Resource Image","background-color":"rgba(200,200,200,0.3)","height":!content.contentDetail ? 500 : 200,"cover":"","id":"thumbnail"}})],1)],1)],1):_vm._e(),(
                                  content.contentDetail && content.imageOnLeft
                                )?_c('v-spacer'):_vm._e(),(content.contentDetail)?_c('v-col',{staticClass:"text-left ma-0 pa-0",attrs:{"cols":"12","md":content.imageRequired ? '8' : '12',"sm":content.imageRequired ? '8' : '12',"xs":"12"}},[_c('div',{staticClass:"appFont1 v-html__margin",class:content.imageOnLeft ? 'ml-2' : 'mr-2',staticStyle:{"overflow":"auto"},domProps:{"innerHTML":_vm._s(content.contentDetail)}})]):_vm._e(),(
                                  content.contentDetail &&
                                  !content.imageOnLeft
                                )?_c('v-spacer'):_vm._e(),(
                                  content.imageRequired &&
                                  content.imageLink &&
                                  !content.imageOnLeft
                                )?_c('v-col',{staticClass:"ma-0 pa-0",class:!content.contentDetail
                                    ? 'text-center'
                                    : 'text-right',attrs:{"align":"center","cols":"12","md":!content.contentDetail ? 12 : 4,"sm":!content.contentDetail ? 12 : 4,"xs":"12"}},[_c('v-row',{staticClass:"my-0 py-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"text-center my-0 py-0"},[_c('v-img',{attrs:{"src":content.imageUrl,"contain":"","cover":"","alt":"Resource Image","background-color":"rgba(200,200,200,0.3)","height":!content.contentDetail ? 500 : 200,"id":"thumbnail"}})],1)],1)],1):_vm._e()],1),(
                                content.contentTable &&
                                content.contentTable.tableRequired
                              )?_c('v-row',{staticClass:"ma-0 py-5",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"text-left ma-0 pa-0",staticStyle:{"max-width":"100%"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"border":"rgb(33, 210, 210) solid 0.1em"},attrs:{"headers":content.contentTable.tableHeaders.filter(
                                      (header) => header.text !== 'Actions'
                                    ),"items":content.contentTable.tableItems,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{class:_vm.appDark
                                          ? 'black darken-3 white--text'
                                          : 'blue lighten-4 black--text',attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(content.contentTable.tableDescription))])],1)]},proxy:true}],null,true)})],1)],1):_vm._e()],1)}),1):_vm._e()],1)],1),(!_vm.isTemplate && _vm.alphaResourceViewTags.length)?_c('v-row',{staticClass:"ma-0 px-0 py-3 mt-3"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-card-text',{staticClass:"ma-0 pa-0"},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"ma-0 pa-0 text-left"},_vm._l((_vm.isTemplate
                                  ? _vm.constructorResource.tags
                                  : _vm.alphaResourceViewTags),function(tag,index){return _c('v-chip',{key:index,staticClass:"ma-1",staticStyle:{"height":"25px"},attrs:{"dense":"","small":"","color":_vm.randomColor,"text-color":_vm.appDark ? 'black' : 'white'}},[_vm._v(" #"+_vm._s(tag)+" ")])}),1)],1)],1)],1)],1):_vm._e(),(!_vm.isTemplate && _vm.alphaResourceViewReferences.length)?_c('v-row',{staticClass:"my-2 py-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"text-left my-0 py-0",attrs:{"align":"center","cols":"12","md":"12","sm":"12"}},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("References")]),_c('v-card-text',{staticClass:"mt-2 mb-5 py-0 ml-2 px-0"},_vm._l((_vm.alphaResourceViewReferences),function(reference,referenceIndex){return _c('div',{key:referenceIndex,staticClass:"my-0 py-0 mx-0 px-0"},[_c('v-row',{staticClass:"my-0 py-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"my-0 py-0 text-right mx-0 px-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"my-0 py-0 ml-5 px-0",attrs:{"justify":"start"}},[_c('v-col',{staticClass:"pl-2 my-0 py-0 text-left",staticStyle:{"max-width":"35px"},attrs:{"cols":"1","sm":"1","md":"1"}},[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(referenceIndex + 1)+". ")])]),_c('v-col',{staticClass:"text-left ml-n2 px-0 my-0 py-0",attrs:{"cols":"8","sm":"8","md":"8"}},[_c('span',{staticClass:"appFont1 my-0 py-0",domProps:{"innerHTML":_vm._s(reference)}})])],1)],1)],1)],1)}),0)],1)],1):_vm._e()],1)],1)],1)],1)],1)],1)])],1)],1):_c('div',[_c('progress-circular')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }