<template>
  <v-container fluid class="ma-0 pa-0">
    <!-- Main data -->
    <div v-if="user && token" class="ma-0 pa-0">
      <v-row justify="center" align="center" class="ma-0 pa-0">
        <!-- <v-btn @click="test">Test</v-btn> -->
        <v-col cols="12" md="12" sm="12" class="text-center ma-0 pr-0 pl-3">
          <!-- Title  -->
          <v-row class="ma-0 pa-0" justify="start">
            <v-col cols="12" class="ma-0 pa-0 text-left" align="center">
              <v-card-title class="justify-start mx-0 px-0"
                >Order Summary</v-card-title
              >
            </v-col>
          </v-row>
          <!-- Order Summary Details -->
          <v-row class="ml-0 mb-5 pa-0" justify="start">
            <v-col cols="12" class="ma-0 pa-0" align="start">
              <div class="ml-3 mr-10">
                <!-- subtotal -->
                <v-row class="my-2 pa-0 appFont1">
                  <v-col class="ma-0 pa-0" cols="8">Item Subtotal</v-col>
                  <v-col cols="4" class="ma-0 pa-0 text-right"
                    >{{ appCurrency }}
                    {{ itemTotalBeforeDiscount.toFixed(2) }}</v-col
                  >
                </v-row>
                <!-- Order Discounts -->
                <v-row class="my-2 pa-0 appFont1">
                  <v-col class="ma-0 pa-0" cols="8">You save</v-col>
                  <v-col
                    cols="4"
                    class="ma-0 pa-0 text-right red--text text--darken-4 font-weight-medium"
                  >
                    - {{ appCurrency }} {{ cartDiscount.toFixed(2) }}</v-col
                  >
                </v-row>
                <!-- Item total after discounts -->
                <v-row class="my-2 pa-0 appFont1">
                  <v-col class="ma-0 pa-0" cols="8">Total After Discount</v-col>
                  <v-col
                    cols="4"
                    class="ma-0 pa-0 text-right text--darken-4 font-weight-medium"
                  >
                    {{ appCurrency }}
                    {{ parseFloat(cartSubTotal).toFixed(2) }}</v-col
                  >
                </v-row>
                <!-- Promo code and shipping stuff , Total Exstimate-->
                <div v-if="checkoutModel === 4">
                  <!-- Adding promo code -->
                  <v-form
                    @submit.prevent="checkPromoCode"
                    class="ma-0 pa-0"
                    v-model="isPromocodeFormValid"
                    ref="promoCodeForm"
                  >
                    <v-row
                      v-if="!isPromoCodeVerified && promoCodeActive"
                      class="my-2 pa-0"
                    >
                      <div></div>
                      <v-col class="ma-0 pa-0" cols="6">Promo Code</v-col>
                      <v-spacer></v-spacer>
                      <v-col
                        cols="6"
                        md="3"
                        sm="6"
                        style="min-width: 150px"
                        class="ma-0 pa-0"
                      >
                        <v-text-field
                          id="promo__code--textbox"
                          class="ma-0 pa-0"
                          dense
                          outlined
                          v-model="promoCode"
                          append-icon="mdi-tag"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0" justify="start">
                      <v-col class="ma-0 pa-0 d-flex text-left">
                        <!-- Verify promo code -->
                        <v-btn
                          v-if="!isPromoCodeVerified && promoCode"
                          :disabled="!isPromocodeFormValid || !promoCode"
                          small
                          @click="checkPromoCode"
                          class="font-weight-bold app__button"
                          :class="appDark ? 'blue darken-4' : 'blue lighten-4'"
                        >
                          <v-icon small left>mdi-check</v-icon>
                          Verify
                        </v-btn>
                        <v-spacer></v-spacer>
                        <!-- Manage Promotion -->
                        <v-btn
                          v-if="
                            (!promoCodeActive && !isPromoCodeVerified) ||
                            (promoCode && isPromoCodeVerified)
                          "
                          small
                          @click="managePromoCode()"
                          class="font-weight-bold app__button mr-0"
                          :class="
                            appDark ? 'green darken-4' : 'green lighten-4'
                          "
                        >
                          <v-icon small left>{{
                            promoCode ? 'mdi-pencil' : 'mdi-plus'
                          }}</v-icon>
                          {{
                            promoCode ? 'Change promoCode' : 'Apply promotion'
                          }}
                        </v-btn>
                        <!-- Remove promotion -->
                        <v-tooltip
                          v-if="isPromoCodeVerified"
                          bottom
                          class="mx-5"
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              x-small
                              icon
                              @click="removePromotion()"
                              class="ml-5 mt-1 error"
                            >
                              <v-icon>close</v-icon>
                            </v-btn>
                          </template>
                          <span>Remove Promotion</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-form>
                  <!-- Verified Promo code -->
                  <v-row v-if="isPromoCodeVerified" class="my-2 pa-0 appFont1">
                    <v-col class="ma-0 pa-0" cols="8">Promotion Applied</v-col>
                    <v-col cols="4" class="ma-0 pa-0 text-right"
                      ><v-icon class="mr-2" color="primary">verified</v-icon
                      >{{ verifiedPromoCode }}</v-col
                    >
                  </v-row>
                  <!-- promotion applied -->
                  <v-row v-if="isPromoCodeVerified" class="my-2 pa-0 appFont1">
                    <v-col class="ma-0 pa-0" cols="8">Promotion Applied</v-col>
                    <v-col cols="4" class="ma-0 pa-0 text-right"
                      >{{ appCurrency
                      }}{{ parseFloat(promotionalDiscount).toFixed(2) }}</v-col
                    >
                  </v-row>
                  <!-- Estimated Tax -->
                  <v-row
                    v-if="appCurrencyText === 'cad'"
                    class="my-2 pa-0 appFont1"
                  >
                    <v-col class="ma-0 pa-0" cols="8">Estimated Tax</v-col>
                    <v-col cols="4" class="ma-0 pa-0 text-right"
                      >{{ appCurrency }}
                      {{ parseFloat(cartTaxAfterPromotion).toFixed(2) }}</v-col
                    >
                  </v-row>
                  <!-- Items Total after tax and discount -->
                  <v-row class="my-2 pa-0 appFont1">
                    <v-col class="ma-0 pa-0" cols="8"
                      >Item total after discount</v-col
                    >
                    <v-col
                      v-if="appCurrencyText === 'cad'"
                      cols="4"
                      class="ma-0 pa-0 text-right"
                      >{{ appCurrency }}
                      {{ parseFloat(itemTotalAfterDiscount).toFixed(2) }}</v-col
                    >
                  </v-row>
                  <!-- Estimated Shipping -->
                  <v-row class="my-2 pa-0 appFont1">
                    <v-col class="ma-0 pa-0" cols="8">Estimated Shipping</v-col>
                    <v-col cols="4" class="ma-0 pa-0 text-right"
                      >{{ appCurrency }} {{ cartShippingFee.toFixed(2) }}</v-col
                    >
                  </v-row>
                  <!-- Estimated Total -->
                  <v-row class="mt-10 pa-0 appFont1 font-weight-medium">
                    <v-col class="ma-0 pa-0" cols="8">Estimated Total</v-col>
                    <v-col cols="4" class="ma-0 pa-0 text-right"
                      >{{ appCurrency }}
                      {{ parseFloat(estimatedTotal).toFixed(2) }}</v-col
                    >
                  </v-row>
                </div>
              </div>
              <!-- Proceed to Checkout -->
              <v-row class="mt-10 pa-0 mr-5" justify="center" dense>
                <v-col cols="12" class="ma-0 pa-0" align="center">
                  <!-- Continue -->
                  <v-btn
                    :disabled="!continueToNextSection"
                    style="width: 100%; max-width: 500px"
                    text
                    class="app__button mx-0 px-0"
                    :class="appDark ? 'blue darken-4' : 'blue lighten-4'"
                    @click="proceedToCheckout()"
                  >
                    <v-icon v-if="currentRoute !== 'checkout'"
                      >shopping_cart_checkout</v-icon
                    >
                    <span
                      :class="currentRoute !== 'checkout' ? 'ml-2' : 'ml-0'"
                      >{{
                        currentRoute === 'checkout' && checkoutModel === 4
                          ? 'Place Order'
                          : currentRoute === 'checkout'
                          ? 'Continue'
                          : 'Proceed to Checkout'
                      }}</span
                    >
                  </v-btn>
                  <!-- Back -->
                  <v-btn
                    style="width: 100%; max-width: 500px"
                    text
                    v-if="
                      currentRoute === 'checkout' &&
                      this.checkoutModel > 1 &&
                      this.checkoutModel !== '1'
                    "
                    class="app__button mx-0 px-0 mt-5"
                    :class="appDark ? 'green darken-4' : 'green lighten-4'"
                    @click="goToPreviousSection()"
                  >
                    Back
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <!-- Displaying The Spinner while loading -->
    <div v-else>
      <progress-circular></progress-circular>
    </div>
  </v-container>
</template>
<script>
// Importing required modules
import moment from 'moment';
import { mapGetters } from 'vuex';
import { eventBus } from '../../shared/eventBus';
export default {
  name: 'orderSummary',
  data() {
    return {
      // reviewsByUserDataReceived: false,
      pageTitle: 'Your Cart',
      isPromoCodeVerified: false,
      verifiedPromoCode: '',
      progress: null,
      isPromocodeFormValid: false,
      promoCode: '',
      promotionalDiscount: null,
      promoCodeInfo: null,
      promoCodeActive: false,
      // confirmDialog: false,
      // confirmDialogText: "some Dialog text",
    };
  },
  computed: {
    ...mapGetters([
      'user',
      'token',
      'error',
      'errorStatus',
      'loading',
      'appCurrency',
      'appCurrencyText',
      'appDark',
      'appThemeFontColor',
      'validationRules',
      'initializeAppComplete',
      'randomColor',
      'cartSubTotal',
      'cartDiscount',
      'cartShippingFee',
      'cartConvenienceFee',
      // "cartTotal",
      'cartTax',
      'serverDomain',
      'addCellNumberDialog',
      'defaultCart',
      'orderInProgressPhoneVerificationFlag',
      // "progressing",
    ]),
    progressing: {
      get() {
        return this.$store.getters.progressing;
      },
      set(value) {
        this.$store.commit('setProgressing', value);
      },
    },
    itemTotalBeforeDiscount() {
      return this.cartSubTotal + this.cartDiscount;
    },
    itemTotalAfterDiscount() {
      switch (this.appCurrencyText) {
        case 'cad':
          return (
            Math.round(
              (this.cartSubTotal * 100 -
                this.promotionalDiscount * 100 +
                this.cartTaxAfterPromotion * 100) *
                100
            ) / 100
          );
        case 'inr':
          return this.cartSubTotal - this.promotionalDiscount;
        default:
          break;
      }
    },
    estimatedTotal() {
      switch (this.appCurrencyText) {
        case 'cad':
          return (
            Math.round(
              (this.cartSubTotal * 100 +
                this.cartTaxAfterPromotion * 100 -
                this.promotionalDiscount * 100 +
                this.cartShippingFee * 100) *
                100
            ) / 100
          );
        case 'inr':
          return (
            this.cartSubTotal - this.promotionalDiscount + this.cartShippingFee
          );
        default:
          break;
      }
    },
    cartTaxAfterPromotion() {
      let sum;
      sum =
        Math.round(
          (((this.cartSubTotal - this.promotionalDiscount) *
            process.env.VUE_APP_REGIONAL_TAX) /
            100) *
            100
        ) / 100;
      return sum;
    },
    currentRoute() {
      return this.$router.currentRoute.name;
    },
    continueToNextSection() {
      if (this.currentRoute !== 'checkout') {
        return true;
      } else {
        switch (this.checkoutModel) {
          case 1:
            return this.cart.shippingAddress ? true : false;
          case '1':
            return this.cart.shippingAddress ? true : false;
          case 2:
            return true;
          case '2':
            return true;
          case 3:
            return this.cart.billingAddress ? true : false;
          case '3':
            return this.cart.billingAddress ? true : false;
          case 4:
            return true;
          case '4':
            return true;
        }
      }
    },
    cart: {
      get() {
        return this.$store.getters.cart;
      },
      set(value) {
        this.$store.commit('setCart', value);
      },
    },
    checkoutModel: {
      get() {
        return this.$store.getters.checkoutModel;
      },
      set(value) {
        this.$store.commit('setCheckoutModel', value);
      },
    },
    targetRequired: {
      get() {
        return this.$store.getters.targetRequired;
      },
      set(value) {
        this.$store.commit('setTargetRequired', value);
      },
    },
    targetLink: {
      get() {
        return this.$store.getters.targetLink;
      },
      set(value) {
        this.$store.commit('setTargetLink', value);
      },
    },
    targetText: {
      get() {
        return this.$store.getters.targetText;
      },
      set(value) {
        this.$store.commit('setTargetText', value);
      },
    },
    snackBarText: {
      get() {
        return this.$store.getters.snackBarText;
      },
      set(value) {
        this.$store.commit('setSnackBarText', value);
      },
    },
  },
  async created() {
    // Check if the previous route name is 'verifyOtp'
    if (this.$previousRoute === 'verifyOtp') {
      eventBus.$on('userPhoneNumberVerified', this.proceedToCheckout);
    } else {
      this.checkoutModel = 1;
    }
  },
  beforeDestroy() {
    eventBus.$off('userPhoneNumberVerified', this.proceedToCheckout);
  },
  mounted() {
    this.$nextTick(function () {
      this.progressing = false;
    });
    // this.reviewsByUserDataReceived = true;
  },
  watch: {
    initializeAppComplete(newValue, oldValue) {
      if (oldValue === false) {
        //   Do action for this Page
      }
    },
    $route(to, from) {
      // Check data received and perform action
      // this.getReviewsByUser();
    },
    cartSubTotal() {
      if (this.promoCodeInfo && this.promoCodeInfo.valid) {
        switch (this.promoCodeInfo.discountType) {
          case 'percent':
            switch (this.appCurrencyText) {
              case 'cad':
                this.promotionalDiscount = (
                  (this.cartSubTotal.toFixed(2) *
                    this.promoCodeInfo.discountValue) /
                  100
                ).toFixed(2);
                break;
              case 'inr':
                this.promotionalDiscount = (
                  (this.cartSubTotal.toFixed(2) *
                    this.promoCodeInfo.discountValue) /
                  100
                ).toFixed(2);
                break;

              default:
                break;
            }

            break;
          case 'amount':
            this.promotionalDiscount = this.promoCodeInfo.discountValue;
            break;
          default:
            break;
        }
      }
    },
    checkoutModel(new_value, old_value) {
      this.$nextTick(() => {
        if (this.$refs.promoCodeForm) {
          this.$refs.promoCodeForm.resetValidation();
        }
      });
    },
  },
  methods: {
    test() {},
    managePromoCode() {
      if (!this.promoCode) {
        this.promoCodeActive = true;
        this.$nextTick(() => {
          // Set focus on promo code text field
          const promoCodeInput = document.getElementById(
            'promo__code--textbox'
          );
          if (promoCodeInput) promoCodeInput.focus();
        });
      } else {
        this.promoCodeActive = true;
        this.isPromoCodeVerified = false;
        this.promoCode = '';
        this.promotionalDiscount = 0;
        this.promoCodeInfo = null;
      }
    },
    removePromotion() {
      this.promoCodeActive = false;
      this.isPromoCodeVerified = false;
      this.promoCode = '';
      this.promotionalDiscount = 0;
      this.promoCodeInfo = null;
    },
    convertTimestamp(date) {
      return moment(date).format('MMM DD, YYYY');
    },
    enableSnackBar(targetRequired, targetText, targetLink) {
      // Assign snackbar parameters
      this.targetRequired = targetRequired;
      this.targetText = targetText;
      this.targetLink = targetLink;
      // show snackbar
      this.$store.commit('setSnackBar', true);
    },
    async proceedToCheckout() {
      if (this.currentRoute === 'checkout' && this.checkoutModel === 4) {
        // Check if user has a phone number registered
        if (!(this.user.cellNumber && this.user.cellNumber.verified)) {
          this.$store.commit('setOrderInProgressPhoneVerificationFlag', true);
          // initiate Add cell number and OTP verification
          this.$router.push({ name: 'addCellNumber' });
          // this.$store.commit('setAddCellNumberDialog', true);
          return; // method will get re-executed once the user phone number is verified
        }
        // Constructing Payment payload
        const payload = {
          orderInput: {
            items: this.cart.items.map((itemObj) => {
              return {
                item: itemObj.item._id,
                quantity: itemObj.quantity,
              };
            }),
            billingAddress: this.cart.billingAddress,
            shippingAddress: this.cart.shippingAddress,
            promoCode: this.verifiedPromoCode ? this.verifiedPromoCode : '',
            promotion: {
              isPercentage:
                this.promoCodeInfo && this.promoCodeInfo.valid
                  ? this.promoCodeInfo.discountType === 'percent'
                  : false,
              value:
                this.promoCodeInfo && this.promoCodeInfo.valid
                  ? this.promoCodeInfo.discountValue
                  : 0,
            },
            subTotal: parseFloat(parseFloat(this.estimatedTotal).toFixed(2)),
          },
        };
        // return;
        // initializeServerAction
        this.$store.dispatch('initializeServerAction');
        // console.log('place order payload:', payload);
        // return;
        // // Stripe Payment
        // let paymentUrlObj = await this.$store.dispatch(
        //   'generateStripePaymentUrl',
        //   payload
        // );
        // Razor Payment
        const paymentUrlObj = await this.$store.dispatch(
          'generateRazorPaymentUrl',
          payload
        );
        // End server action
        this.$store.dispatch('endServerAction');
        // console.log('PaymentObj:', paymentUrlObj);

        if (paymentUrlObj && paymentUrlObj.order_id) {
          delete paymentUrlObj.__typename;
          let options = paymentUrlObj;
          // Add the handler function for redrecting to oreder confirmation page
          // Handle the payment success
          options.handler = (response) => {
            // clear user cart
            if (this.cart && this.cart.items) {
              this.cart.items = [];
            }
            // console.log(response);
            if (this.$route.name !== 'orderConfirmation') {
              this.$router.push({ name: 'orderConfirmation' });
            }
          };

          // Capture the Vue component's 'this' context
          const self = this;

          // on closing dialog without payment
          options.modal = {
            ondismiss: function () {
              // Use 'self' instead of 'this' to refer to the Vue component instance
              self.$router.push({ name: 'orderFailure' }).catch((err) => {
                // Handle any errors with the routing
                console.error(err);
              });
            },
          };

          // Open Razorpay checkout with options
          var rzp1 = new Razorpay(options);
          rzp1.open();
        } else if (paymentUrlObj && paymentUrlObj.url) {
          window.location = paymentUrlObj.url;
        }
      } else if (this.currentRoute === 'checkout') {
        // console.log('cart:', this.cart);
        // console.log('checkoutModel:', this.checkoutModel);
        this.goToNextSection();
        return;
      } else {
        // alert("This will proceed to checkout");
        if (this.$route.name !== 'checkout') {
          this.$router.push({ name: 'checkout' });
        }
      }
    },
    goToNextSection() {
      // alert("This progresses to next section");
      this.checkoutModel++;
    },
    goToPreviousSection() {
      this.checkoutModel--;
    },
    async checkPromoCode() {
      if (this.$refs.promoCodeForm.validate()) {
        const payload = {
          checkPromoCodeInput: {
            promoCode: this.promoCode,
          },
        };

        this.promoCodeInfo = await this.$store.dispatch(
          'checkPromoCode',
          payload
        );
        if (this.promoCodeInfo && this.promoCodeInfo.valid) {
          this.isPromoCodeVerified = true;
          this.verifiedPromoCode = this.promoCode;
          // console.log('prmo Info:', this.promoCodeInfo);
          switch (this.promoCodeInfo.discountType) {
            case 'percent':
              switch (this.appCurrencyText) {
                case 'cad':
                  this.promotionalDiscount = (
                    (this.cartSubTotal.toFixed(2) *
                      this.promoCodeInfo.discountValue) /
                    100
                  ).toFixed(2);
                  break;
                case 'inr':
                  this.promotionalDiscount = (
                    (this.cartSubTotal.toFixed(2) *
                      this.promoCodeInfo.discountValue) /
                    100
                  ).toFixed(2);
                  break;

                default:
                  break;
              }

              break;
            case 'amount':
              this.promotionalDiscount = this.promoCodeInfo.discountValue;
              break;
            default:
              break;
          }
        }
      }
    },
    handleDialog(content, context) {
      this.$store.dispatch('handleDialog', {
        content,
        context,
      });
    },
  },
};
</script>
<style>
.shipping-address__card {
  cursor: pointer;
  color: rgb(196, 74, 74);
}
.shipping-address__card .v-card :hover {
  border: #302b63 solid 1mm;
}
.pointerMouse {
  cursor: auto;
  color: rgb(196, 74, 74);
}
.rightJustified {
  text-align: right;
}
.myfont1 {
  font-size: 14px;
}
.sizeWidth {
  max-width: 30px;
}
.customWidth {
  max-width: 80px;
}
.inputValue input::-webkit-outer-spin-button,
.inputValue input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.disable-events {
  pointer-events: none;
}
.tipsDialogTitleBackground {
  background: #0f0c29; /* fallback for old browsers */
  color: white;
  background: -webkit-linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #24243e,
    #302b63,
    #0f0c29
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.customDecoration.v-text-field > .v-input__control > .v-input__slot:before {
  border-style: none;
}
.customDecoration.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.image_required__checkbox .v-input__control .v-input__slot label {
  font-size: 14px;
  font-weight: 500;
}
</style>
