<!-- Ediatable Dialog -->
<template>
  <!-- Dialog for Add/Update Addresss -->
  <v-row class="my-0 py-0" style="width: 100%">
    <v-col class="my-0 py-0">
      <v-dialog v-model="addressDialog" persistent scrollable max-width="800px">
        <v-card max-width="800" max-height="800" style="overflow: hidden">
          <v-card-title
            class="font-weight-bold"
            :class="appDark ? 'cyan darken-4' : 'light-blue lighten-3'"
          >
            {{
              addressContext === 'add' ? 'Add a new address' : 'Update Address'
            }}
          </v-card-title>
          <!-- Address Data -->
          <v-card-text
            style="max-height: 800px"
            class="justify__text mx-0 py-0 px-0 my-0"
          >
            <!-- container -->
            <v-container class="mx-0 py-0 px-0 my-0">
              <v-form
                :style="appThemeFontColor"
                v-model="isAddressFormValid"
                lazy-validation
                ref="userAddressForm"
                class="py-0 my-0 mx-5"
              >
                <!-- Type of Address-->
                <v-row class="my-0 py-0" justify="space-between">
                  <v-col class="my-0 py-0">
                    <v-radio-group v-model="activeAddress.category" row>
                      <v-row class="my-0 py-0" justify="space-around">
                        <v-col class="my-1 py-0" cols="12" md="6" sm="12">
                          <v-radio
                            label="Residential (9 AM to 9 PM)"
                            value="residential"
                          ></v-radio>
                        </v-col>
                        <v-col class="my-1 py-0" cols="12" md="6" sm="12">
                          <v-radio
                            label="Office (9 AM to 5 PM)"
                            value="office"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <!-- Name -->
                <v-row class="my-0 py-0" justify="start">
                  <!-- First Name -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="center"
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      outlined
                      class="my-0 py-0"
                      type="text"
                      style="font-size: 1.1em"
                      v-model="activeAddress.addresseeFirst"
                      :rules="validationRules.name"
                      placeholder="First Name"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Last Name -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="center"
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-text-field
                      name="Last Name"
                      dense
                      outlined
                      class="my-0 py-0"
                      type="text"
                      style="font-size: 1.1em"
                      :rules="validationRules.name"
                      v-model="activeAddress.addresseeLast"
                      placeholder="Last Name"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- Type of Phone number-->
                <v-row class="my-0 py-0" justify="space-between">
                  <v-col class="mt-0 py-0">
                    <v-radio-group
                      v-model="activeAddress.phoneNumber.category"
                      row
                    >
                      <v-radio label="Mobile" value="Mobile"></v-radio>
                      <v-radio
                        label="Landline"
                        value="Landline"
                        class="ml-10 pl-10"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <!-- Mobile Phone -->
                <v-row
                  v-if="activeAddress.phoneNumber.category === 'Mobile'"
                  class="my-0 py-0 d-flex"
                  justify="start"
                >
                  <!-- Country Code -->
                  <v-col class="my-0 py-0 text-left" cols="4" sm="2" xs="3">
                    <v-text-field
                      dense
                      outlined
                      readonly
                      class="my-0 py-0"
                      style="font-size: 1.1em; min-width: 80px"
                      prepend-icon="phone"
                      placeholder="+91"
                      value="+91"
                    ></v-text-field>
                  </v-col>
                  <!-- 10 digit Number -->
                  <v-col class="my-0 py-0 text-left" cols="8" sm="8" xs="8">
                    <v-text-field
                      name="Mobile Number"
                      dense
                      outlined
                      :rules="validationRules.mobileNumber"
                      class="my-0 py-0 input-value__number"
                      type="number"
                      style="font-size: 1.1em"
                      v-model="activeAddress.phoneNumber.mobileNumber"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- Landline Phone  -->
                <v-row v-else class="my-0 py-0" justify="start">
                  <!-- COuntry Code -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="start"
                    cols="4"
                    md="2"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      outlined
                      readonly
                      class="my-0 py-0"
                      style="font-size: 1.1em"
                      prepend-icon="phone"
                      placeholder="+91"
                    >
                      +91
                    </v-text-field>
                  </v-col>
                  <!-- Area Code -->
                  <v-col
                    class="my-0 py-0 ml-0 pl-0 text-left"
                    align="center"
                    cols="3"
                    md="2"
                    sm="3"
                  >
                    <v-text-field
                      dense
                      outlined
                      placeholder="Area Code"
                      :rules="validationRules.required"
                      class="my-0 py-0 input-value__number"
                      type="number"
                      style="font-size: 1.1em"
                      v-model="activeAddress.phoneNumber.areaCode"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- Local Number -->
                  <v-col
                    class="my-0 py-0 ml-0 pl-0 text-left"
                    align="center"
                    cols="5"
                    md="4"
                    sm="5"
                  >
                    <v-text-field
                      dense
                      outlined
                      :rules="validationRules.required"
                      class="my-0 py-0 input-value__number"
                      placeholder="Local Number"
                      type="number"
                      style="font-size: 1.1em"
                      v-model="activeAddress.phoneNumber.localNumber"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- Line 1 -->
                <v-row class="my-0 py-0" justify="start">
                  <!--  Line 1 -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="center"
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      outlined
                      class="my-0 py-0"
                      :rules="validationRules.required"
                      style="font-size: 1.1em"
                      v-model="activeAddress.line1"
                      type="text"
                      placeholder="Address Line1"
                    >
                    </v-text-field>
                  </v-col>
                  <!--  Line 2 -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="center"
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      outlined
                      class="my-0 py-0"
                      type="text"
                      style="font-size: 1.1em"
                      v-model="activeAddress.line2"
                      placeholder="Address Line2"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- City province postal code  -->
                <v-row class="my-0 py-0" justify="start">
                  <!-- City -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="start"
                    cols="4"
                    md="4"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      outlined
                      type="text"
                      :rules="validationRules.required"
                      class="my-0 py-0"
                      style="font-size: 1.1em"
                      v-model="activeAddress.cityTown"
                      placeholder="City / Town"
                    >
                    </v-text-field>
                  </v-col>
                  <!-- province-->
                  <v-col
                    class="my-0 py-0 ml-0 pl-0 text-left"
                    align="center"
                    cols="4"
                    md="4"
                    sm="4"
                  >
                    <v-select
                      dense
                      outlined
                      :items="statesProvincesList"
                      :rules="validationRules.required"
                      class="my-0 py-0 input-value__number"
                      style="font-size: 1.1em"
                      v-model="activeAddress.stateProvince"
                      placeholder="Province /  State"
                    >
                    </v-select>
                  </v-col>
                  <!-- postal code -->
                  <v-col
                    class="my-0 py-0 ml-0 pl-0 text-left"
                    align="center"
                    cols="4"
                    md="4"
                    sm="4"
                  >
                    <v-text-field
                      dense
                      outlined
                      type="number"
                      class="my-0 py-0 input-value__number"
                      counter
                      maxlength="6"
                      :rules="validationRules.postalCode"
                      style="font-size: 1.1em"
                      placeholder="Postal Code"
                      v-model="activeAddress.postalCode"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <!-- Landmark -->
                <v-row class="my-0 py-0" justify="start">
                  <!--  Line 2 -->
                  <v-col
                    class="my-0 py-0 text-left"
                    align="center"
                    cols="12"
                    md="6"
                    sm="12"
                  >
                    <v-text-field
                      dense
                      outlined
                      class="my-0 py-0"
                      style="font-size: 1.1em"
                      v-model="activeAddress.landmark"
                      placeholder="Landmark"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <!-- Close/cancel buttons -->
          <v-card-actions>
            <v-row justify="center" class="my-2">
              <!-- Save -->
              <v-col md="2" sm="3" class="text-center">
                <v-btn
                  rounded
                  small
                  class="primary darken-4 font-weight-bold"
                  raised
                  color="blue"
                  @click="saveAddressDialog()"
                >
                  Submit
                </v-btn>
              </v-col>
              <!-- Cancel -->
              <v-col md="2" sm="3" class="text-center">
                <v-btn
                  rounded
                  small
                  class="error darken-4 font-weight-bold"
                  raised
                  @click="clearAddressDialog()"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {};
  },
  props: [
    'isDialog',
    'dialogHeading',
    'dialogText',
    'dialogText2',
    'dialogBtn1',
    'dialogBtn2',
    'dialogResult',
    'dialogContext',
  ],

  computed: {
    ...mapGetters([
      'user',
      'error',
      'errorStatus',
      'appDark',
      'appThemeFontColor',
      'validationRules',
      'statesProvinces',
    ]),
    addressContext: {
      get() {
        return this.$store.getters.addressContext;
      },
      set(value) {
        this.$store.commit('setAddressContext', value);
      },
    },
    addressDialog: {
      get() {
        return this.$store.getters.addressDialog;
      },
      set(value) {
        this.$store.commit('setAddressDialog', value);
      },
    },
    isAddressFormValid: {
      get() {
        return this.$store.getters.isAddressFormValid;
      },
      set(value) {
        this.$store.commit('setIsAddressFormValid', value);
      },
    },
    activeAddress: {
      get() {
        return this.$store.getters.activeAddress;
      },
      set(value) {
        this.$store.commit('setActiveAddress', value);
      },
    },
    statesProvincesList() {
      if (this.statesProvinces) {
        return this.statesProvinces.categoryList;
      }
      return [];
    },
  },
  methods: {
    clearAddressDialog() {
      this.addressDialog = false;
      this.activeAddress.category = 'residential';
      this.activeAddress.addresseeFirst = '';
      this.activeAddress.addresseeLast = '';
      this.activeAddress.line1 = '';
      this.activeAddress.line2 = '';
      this.activeAddress.landmark = '';
      this.activeAddress.stateProvince = '';
      this.activeAddress.cityTown = '';
      this.activeAddress.country = 'India';
      this.activeAddress.postalCode = '';
      this.activeAddress.phoneNumber.mobileNumber = '';
      this.activeAddress.phoneNumber.countryCode = '91';
      this.activeAddress.phoneNumber.category = 'Mobile';
      this.activeAddress.phoneNumber.areaCode = '';
      this.activeAddress.phoneNumber.localNumber = '';
      // alert("This will handle address dialog action");
    },
    handleDialog(content, context) {
      // Setting context
      switch (context) {
        // case "disableResource":
        //   this.dialogContext = "disableResource";
        //   break;

        default:
          break;
      }
      // performing action
      switch (this.dialogContext) {
        // case "disableResource":
        //   this.dialogHeading = "Confirm Disabling Resource";
        //   this.dialogText =
        //     "Are you sure you want to disable this resource? This can be enabled later from database. data will remain in the database.";
        //   this.dialogBtn1 = "Cancel";
        //   this.dialogBtn2 = "Yes";
        //   this.isDialog = true;
        //   break;

        default:
          break;
      }
    },
    async saveAddressDialog() {
      // console.log('this will add address');
      // Adding Address to database
      if (this.$refs.userAddressForm.validate()) {
        let payload;
        payload = {
          addressInput: this.activeAddress,
        };
        if (this.addressContext !== 'add') {
          payload.addressId = this.activeAddress._id;
        }
        delete payload.addressInput._id;
        // console.log('payload:', payload);
        // return;
        if (this.addressContext === 'add') {
          let address = await this.$store.dispatch('addAddress', payload);
          if (address) {
            let user = this.user;
            user.addresses.push(address);
            this.$store.commit('setUser', user);
            this.$emit('userAddressesUpdated');
            // console.log('user:', this.user);
          }
        } else {
          let address = await this.$store.dispatch('updateAddress', payload);
          if (address) {
            let user = this.user;
            const existingAddress = user.addresses.find(
              (address) => address._id === payload.addressId
            );
            const index = user.addresses.indexOf(existingAddress);
            user.addresses.splice(index, 1, address);
            this.$store.commit('setUser', user);
            this.$emit('userAddressesUpdated');
          }
        }
        this.clearAddressDialog();
        // console.log('current user:', this.user);
        this.$forceUpdate();
        this.addressDialog = false;
      } else {
        this.$store.dispatch('handleDialog', {
          context: 'formNotValid',
          content: null,
        });
      }
    },
  },
  watch: {
    addressDialog() {
      this.$nextTick(() => {
        this.$refs.userAddressForm.resetValidation();
      });
    },
  },
};
</script>
