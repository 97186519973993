<template>
  <v-list dense nav class="my-0 py-0">
    <!-- single Level Menu -->
    <v-list-item
      class="my-0 py-0"
      v-if="menu.menuType === 'single'"
      link
      :to="`/pages/${menu.routeParam}`"
    >
      <v-list-item-title>{{ menu.name }}</v-list-item-title>
    </v-list-item>
    <!-- Double Level menu -->
    <v-list-group
      v-if="menu.menuType === 'double'"
      ripple
      :value="false"
      class="my-0 py-0"
    >
      <template v-slot:activator>
        <v-list-item-content class="pa-0">
          <v-list-item-title> {{ menu.name }} </v-list-item-title>
        </v-list-item-content>
      </template>
      <!--SubMenus-->
      <v-list-item
        v-for="(subMenu, subMenuIndex) in menu.subMenus"
        :key="'subMenu' + subMenuIndex"
        link
        :to="`/pages/${subMenu.routeParam}`"
        style="margin-left: 20px"
      >
        <v-list-item-title style="margin-left: 20px">{{
          subMenu.name
        }}</v-list-item-title>
      </v-list-item>
    </v-list-group>
    <!-- Triple Level Menu-->
    <v-list-group
      v-if="menu.menuType === 'triple'"
      ripple
      :value="false"
      class="my-0 py-0"
    >
      <template v-slot:activator>
        <v-list-item-content class="pa-0">
          <v-list-item-title> {{ menu.name }} </v-list-item-title>
        </v-list-item-content>
      </template>
      <!-- Submenus-->
      <v-list
        nav
        dense
        class="my-0 py-0"
        v-for="(subMenu, subMenuIndex) in menu.subMenus"
        :key="'subMenu' + subMenuIndex"
      >
        <v-list-group :value="false" dense ripple no-action sub-group>
          <template v-slot:activator>
            <v-list-item-content class="pa-0" style="margin-left: -10px">
              <v-list-item-title>{{ subMenu.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- Subtitles -->
          <v-list-item
            v-for="(subTitle, subTitleIndex) in subMenu.subTitles"
            :key="'subTitle' + subTitleIndex"
            :to="`/pages/${subTitle.routeParam}`"
            link
          >
            <v-list-item-title style="margin-left: -20px">{{
              subTitle.name
            }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-list-group>
  </v-list>
</template>
<script>
export default {
  name: 'menuTemplate',
  data() {
    return {};
  },
  props: ['menu'],
  methods: {},
};
</script>
