var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"mx-0 pa-0",attrs:{"fluid":""}},[(_vm.user && _vm.token)?_c('div',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center",staticStyle:{"max-width":"1200px"},attrs:{"cols":"12","md":"12","sm":"12"}},[_c('div',{staticClass:"mt-0 pt-0"},[_c('v-card',{staticClass:"mt-0 pb-2 mb-0",staticStyle:{"border-radius":"0","overflow":"auto"},attrs:{"min-height":"500","scrollable":""}},[_c('v-row',{staticClass:"ma-0 pa-0 d-flex"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-card-title',[_vm._v(" Billing Address ")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"mt-2 mx-5 pa-0 text-right"},[_c('v-btn',{staticClass:"blue app__button",class:_vm.appDark ? 'darken-3' : 'lighten-4',attrs:{"small":""},on:{"click":function($event){return _vm.addNewAddress()}}},[_c('v-icon',[_vm._v("add_business")]),_c('span',{staticClass:"ml-2"},[_vm._v("New Address")])],1)],1)],1),_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{staticClass:"ma-0 pa-0"},[(_vm.user.addresses && _vm.user.addresses.length)?_c('v-card-text',{staticClass:"mx-0 px-0 mb-3",staticStyle:{"height":"600px"}},_vm._l((_vm.billingAddresses),function(address,index){return _c('div',{key:'address' + index,attrs:{"id":'billingAddress' + index}},[_c('v-hover',{staticClass:"mb-2",scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [(address != null)?_c('div',{staticClass:"pb-3"},[_c('v-card',{staticClass:"mx-3 py-2",class:{ 'on-hover': hover },staticStyle:{"border":"1px solid grey"},style:(_vm.cart.billingAddress &&
                            address._id === _vm.cart.billingAddress
                              ? 'border: 2px solid skyblue;'
                              : ''),attrs:{"elevation":hover ? 16 : 2,"hover":""},on:{"click":function($event){return _vm.assignBillingAddress(address)}}},[_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"11","md":"11","sm":"9","align":"start"}},[_c('v-card-text',{staticClass:"my-2 py-2"},[_c('v-row',[(address)?_c('v-col',[_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(address.addresseeFirst)+" "+_vm._s(address.addresseeLast)+" ")]),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(address.line1)+" "+_vm._s(address.line2)+" ")]),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(address.cityTown)+", "+_vm._s(address.stateProvince)+", "+_vm._s(address.postalCode)+" ")]),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" "+_vm._s(address.country)+" ")]),_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" +"+_vm._s(address.phoneNumber.countryCode)+"-"+_vm._s(address.phoneNumber.category === 'Mobile' ? '(' + address.phoneNumber.mobileNumber.substring( 0, 3 ) + ')-' + address.phoneNumber.mobileNumber.substring( 3, 6 ) + '-' + address.phoneNumber.mobileNumber.substring( 6, 10 ) : address.phoneNumber.areaCode + '-' + address.localNumber)+" ")]),(address.landmark)?_c('p',{staticClass:"ma-0 pa-0"},[_vm._v(" Landmark: "+_vm._s(address.landmark)+" ")]):_vm._e()]):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"cols":"3","md":"1","sm":"3","align-self":"center"}},[(
                                  _vm.cart.billingAddress &&
                                  address._id === _vm.cart.billingAddress
                                )?_c('v-icon',{staticClass:"success--text mb-2"},[_vm._v("check_circle")]):_vm._e()],1)],1)],1)],1):_vm._e()]}}],null,true)})],1)}),0):_vm._e()],1)],1)],1)],1)])],1)],1):_c('div',[_c('progress-circular')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }